import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Nav from '../components/Nav/Nav'

const IndexPage = () => (
  <Layout>
    <SEO title="Address" keywords={[`gatsby`, `application`, `react`]} />
    <Nav />
    
    <h3>Address:</h3>
    <ul>
      <li>3215 Calaveras Rd, Milpitas, CA 95035</li>
      <li><a href="https://www.google.com/maps/place/Thien+Long+Son+-+The+Dragon+Mountain/@37.4524181,-121.8504545,15z/data=!4m5!3m4!1s0x0:0xfa8cda97963d7f1c!8m2!3d37.4524181!4d-121.8504545?hl=en" target="_blank" rel="noopener noreferrer">Google Map</a></li>
    </ul>

    <h3>Parking:</h3>
    <ul>
      <li>Please park in one of the parking lots.</li>
      <li>Don't park along the small street in the front. You may get a parking ticket.</li>
    </ul>
    
  </Layout>
)

export default IndexPage
